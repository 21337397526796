import {disciplinesCreate, disciplinesDeletex, disciplinesFetch, disciplinesPaginate, disciplinesRemove, disciplinesRestore, disciplinesTrash, disciplinesUpdate} from "@/modules/disciplines/disciplines-service";
import {ca} from "date-fns/locale";

const state = {
    disciplines: [],
    paginate: {},
    load: null,
};
const idSchool = 1;
const getters = {
    getDis() {
        try {
            return state.disciplines.data;
        } catch (e) {
            return [];
        }
    },
    getDisciplines(state) {
        const result = state.disciplines.data.map((item) => ({
            ...item,
            showEdit: false,
        }));

        return result;
    },
    getPaginate(state) {
        const data = {...state.paginate};
        return data;
    },
    getLoad(state) {
        return state.load;
    },
};

const mutations = {
    SET_DISCIPLINES(state, disciplines) {
        state.disciplines = disciplines;
    },
    SET_PAGINATE(state, paginate) {
        state.paginate = {
            page: paginate.page,
            per_page: paginate.limit,
            total: paginate.total,
            total_pages: paginate.total_pages,
            data: paginate.data
        };
    },
    SET_LOAD(state, load) {
        state.load = load;
    },
};

const actions = {
    setLoadNull({commit}) {
        commit("SET_LOAD", null);
    },
    async getDisciplines({commit}) {
        try {
            const response = await disciplinesFetch(idSchool);
            const {data} = response;
            commit("SET_DISCIPLINES", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async disciplinesCreate({commit, dispatch}, payload) {
        try {
            //console.log('disciplinesCreate',payload);
            const response = await disciplinesCreate(idSchool, payload);
            dispatch("getDisciplines", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async disciplinesUpdate({commit, dispatch}, payload) {
        try {
            const response = await disciplinesUpdate(idSchool, payload);
            dispatch("getDisciplines", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async disciplinesDeletex({commit}, query) {
        try {
            const response = await disciplinesDeletex(idSchool, query);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async disciplinesPaginate({commit}, query) {
        try {
            const response = await disciplinesPaginate(idSchool, query);
            const {data} = response;
            commit("SET_PAGINATE", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            commit("SET_LOAD", null);
            console.log(error);
        }
    },

    async disciplinesRemove({commit}, payload) {
        try {
            const response = await disciplinesRemove(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },

    async disciplinesTrash({commit}, query) {
        try {
            const response = await disciplinesTrash(idSchool, query);
            commit("SET_PAGINATE", response.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async disciplinesRestore({commit}, payload) {
        try {
            const response = await disciplinesRestore(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations,
};
