export default {
  title: 'Turma',
  plural: 'Turmas',
  upper: 'TURMA',
  upperPlural: 'TURMAS',
  lower: 'turma',
  lowerPlural: 'turmas',
  erroLoadPaginate: "Erro ao carregar dados das turmas.",
  erroLoadTrash: "Erro ao carregar dados das turmas da lixeira.",
  itemRemovidoLixeira: "Turmas movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover a turma para a lixeira, existem dados vinculados a esse registro, remova os dados para excluir a turma.",
  itemExcuidoLixeira: "Turmas excluídas da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir turmas da lixeira.",
  itemRestauradoLixeira: " Turmas restaurada com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar turma.",
  createTitle: "Nova turma",
  create: "Turma criada com sucesso.",
  update: "Turma editada com sucesso.",
  updateTitle: "Editar turma",
  createError: "Erro ao criar a turma.",
  updateError: "Erro ao editar a turma.",
  inputs: {
    id: null,
    identifier: null,
    label: null,
    id_courses: null,
    id_levels: null,
    id_curriculums: null,
    id_physical_rooms: null,
    id_periods: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da turma",
      example: "1",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador da turma",
      example: "A",
    },
    label: {
      label: "Nome",
      description: "Nome da turma",
      example: "1A",
    },
    id_courses: {
      label: "Curso",
      description: "Curso da turma",
      example: "1",
    },
    id_levels: {
      label: "Nível",
      description: "Nível da turma",
      example: "1",
    },
    id_curriculums: {
      label: "Grade",
      description: "Grade da turma",
      example: "1",
    },
    id_physical_rooms: {
      label: "Sala",
      description: "Sala da turma",
      example: "1",
    },
    id_periods: {
      label: "Período",
      description: "Período da turma",
      example: "1",
    },

    created_at: {
      label: "Data de criação",
      description: "Data de criação da turma",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da turma",
      example: "2021-01-01 00:00:00",
    }
  },

  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    label: {
      label: "Nome",
      key: "label",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    // id_courses: {
    //   label: "Curso",
    //   key: "id_courses",
    // },
    // id_levels: {
    //   label: "Nível",
    //   key: "id_levels",
    // },
    // id_curriculums: {
    //   label: "Grade",
    //   key: "id_curriculums",
    // },
    // id_physical_rooms: {
    //   label: "Sala",
    //   key: "id_physical_rooms",
    // },
    // id_periods: {
    //   label: "Período",
    //   key: "id_periods",
    // },
  },
}
