import auth from "./auth";
import rules from '@/modules/rules/rules-store';
import authfack from "./authfack";
import policies from "@/modules/policies/policies-store";
import postTypes from "./postTypes";
import permissions from "@/modules/permissions/permissions-store";
import users from "@/view/pages/users/store";
import cities from "@/view/pages/cities/store";
import states from "@/view/pages/states/store";
import taxonomiesTypes from "./taxonomiesTypes";
import items from "@/view/pages/plans/items/store";
import plans from "@/view/pages/plans/plans/store";
import profiles from "@/view/pages/profiles/store";
import countries from "@/view/pages/countries/store";
import taxonomies from "@/view/pages/taxonomies/store";
import posts from "@/view/pages/posts-management/store";
import peoples from "@/view/pages/peoples/peoples/store";
import gatewaysPayments from "@/view/pages/payments/gateways/store";
import peoplesRaces from "@/view/pages/peoples/peoples-races/store";
import peoplesTypes from "@/view/pages/peoples/peoples-types/store";
import school from "@/view/pages/configurations/school-account/store";
import productsTypes from "@/view/pages/products/products-types/store";
import documentsTypes from "@/view/pages/peoples/documents-types/store";
import statusCheckout from "@/view/pages/payments/status-checkout/store";
import paymentsStatus from "@/view/pages/payments/payments-status/store";
import paymentsMethods from "@/view/pages/payments/payments-methods/store";
import responsiblesPeoples from "@/view/pages/peoples/responsibles-peoples/store";
import gatewayPaymentsConfig from "@/view/pages/payments/gateway-payments-config/store";
import branchSchoolsConfigurations from "@/view/pages/branch-schools-configuration/store";
import gatewaysPaymentsMethods from "@/view/pages/payments/gateways-payments-methods/store";
import responsiblesPeoplesTypes from "@/view/pages/peoples/reponsibles-peoples-types/store";
import disciplines from "@/modules/disciplines/disciplines-store";
import physicalRoomsTypes from '@/modules/physical-rooms-types/physical-rooms-types-store';
import physicalRoomsResources from '@/modules/physical-rooms-resources/physical-rooms-resources-store';
import physicalRooms from '@/modules/physical-rooms/physical-rooms-store';
import knowledgeAreas from '@/modules/knowledge-areas/knowledge-areas-store';
import teachings from '@/modules/teachings/teachings-store';
import levels from '@/modules/levels/levels-store';
import courses from '@/modules/courses/courses-store';
import periods from '@/modules/periods/periods-store';
import itineraries from '@/modules/itineraries/itineraries-store';
import curriculums from '@/modules/curriculums/curriculums-store';
import classes from '@/modules/classes/classes-store';
import trails from '@/modules/trails/trails-store';
import classesTrails from '@/modules/classes-trails/classes-trails-store';
import levelsCapacityPerYears from '@/modules/levels-capacity-per-years/levels-capacity-per-years-store';
import schoolEnrollments from '@/modules/schools-enrollments/schools-enrollments-store';
import payProducts from "@/modules/pay-products/pay-products-store"
import payMethods from "@/modules/pay-methods/pay-methods-store"
import payInvoicesDocumentsFiscal from "@/modules/pay-invoices-documents-fiscal/pay-invoices-documents-fiscal-store";
import payStatus from "@/modules/pay-status/pay-status-store";
import payInvoices from "@/modules/pay-invoices/pay-invoices-store";
import cards from "@/modules/cards/cards-store"
import stages from "@/modules/stages/stages-store"
import stagesStatus from "@/modules/stages-status/stages-status-store"
import cardsTypes from "@/modules/cards-types/cards-types-store"
import cardsLevels from "@/modules/cards-levels/cards-levels-store"
import cardsRoles from "@/modules/cards-roles/cards-roles-store"
import cardsComments from "@/modules/cards-comments/cards-comments-store"
import cardsFiles from "@/modules/cards-files/cards-files-store"
import cardsLogs from "@/modules/cards-logs/cards-logs-store"
import pipelines from "@/modules/pipelines/pipelines-store"
import forms from "@/modules/forms/forms-store"
import formsFields from "@/modules/forms-fields/forms-fields-store";
import formsModules from "@/modules/forms-modules/forms-modules-store";
import formsFieldsTypes from "@/modules/forms-fields-types/forms-fields-types-store";
import formsFieldsValues from "@/modules/forms-fields-values/forms-fields-values-store";
import holidays from "@/modules/holidays/holidays-store";
import periodicModels from "@/modules/periodic-models/periodic-models-store";
import periodicTypes from "@/modules/periodic-types/periodic-types-store";
import payProductsTypes from "@/modules/pay-products-types/pay-products-types-store";
import pay from "@/modules/pay/pay-store"
import peoplesSpecialtiesTypes from "@/modules/peoples-specialties-types/peoples-specialties-types-store";
import calendars from "@/modules/calendars/calendars-store";
import calendarsTypes from "@/modules/calendars-types/calendars-types-store";
import years from "@/modules/years/years-store";
import months from "@/modules/months/months-store";
import weeks from "@/modules/weeks/weeks-store";
import daysOfWeeks from "@/modules/days-of-weeks/days-of-weeks-store";
import postsConfigs from "@/modules/posts-configs/posts-configs-store";
import PayProductsTypes from "@/modules/pay-products-types/pay-products-types-store";
import AssessmentsTypes from "@/modules/assessments_types/assessments_types-store";


export default {
  postsConfigs,
  payProducts,
  pipelines,
  periodicModels,
  calendars,
  calendarsTypes,
  periodicTypes,
  payStatus,
  payInvoices,
  payMethods,
  payInvoicesDocumentsFiscal,
  physicalRooms,
  knowledgeAreas,
  teachings,
  holidays,
  levels,
  courses,
  periods,
  years,
  months,
  weeks,
  daysOfWeeks,
  itineraries,
  curriculums,
  classes,
  trails,
  classesTrails,
  levelsCapacityPerYears,
  schoolEnrollments,
  disciplines,
  physicalRoomsResources,
  physicalRoomsTypes,
  auth,
  pay,
  forms,
  formsFields,
  formsModules,
  formsFieldsTypes,
  formsFieldsValues,
  cards,
  cardsRoles,
  cardsComments,
  cardsFiles,
  cardsLogs,
  stages,
  stagesStatus,
  cardsTypes,
  cardsLevels,
  posts,
  users,
  rules,
  items,
  plans,
  cities,
  states,
  school,
  peoples,
  policies,
  profiles,
  authfack,
  postTypes,
  countries,
  taxonomies,
  permissions,
  peoplesTypes,
  peoplesRaces,
  productsTypes,
  paymentsStatus,
  payProductsTypes,
  statusCheckout,
  documentsTypes,
  paymentsMethods,
  taxonomiesTypes,
  gatewaysPayments,
  responsiblesPeoples,
  gatewayPaymentsConfig,
  gatewaysPaymentsMethods,
  responsiblesPeoplesTypes,
  branchSchoolsConfigurations,
  peoplesSpecialtiesTypes,
  PayProductsTypes,
  AssessmentsTypes,

};
