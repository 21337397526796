import {
  deleteprofiles,
  fetchProfiles,
  fetchTrash,
  removeprofiles,
  restoreprofiles,
  saveprofiles,
} from "../services/services.js";
import { mountMessage } from "@/utils/MountMessage";

const state = {
  profiles: [],
  isTrash: false,
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
};

const getters = {
  profiles(state) {
    return state.profiles;
  },
  isTrash(state) {
    return state.isTrash;
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_PROFILES(state, payload) {
    state.profiles = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },

  SET_PROFILES_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_IS_TRASH(state, payload) {
    state.isTrash = payload;
  },

  SET_PROFILES_OPTIONS(state, payload) {
    state.paymentsOptions = payload;
  },
};

const actions = {
  async getProfiles({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchProfiles(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", false);
        commit("SET_PROFILES", response.data);
        commit("SET_PROFILES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getTrash({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchTrash(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", true);
        commit("SET_PROFILES", response.data);
        commit("SET_PROFILES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async save({ commit, dispatch }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await saveprofiles(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getProfiles", {});
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async remove({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await removeprofiles(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getProfiles", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async restore({ commit, dispatch }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await restoreprofiles(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async delete({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await deleteprofiles(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
