import {periodsCreate, periodsDeletex, periodsFetch, periodsPaginate, periodsRemove, periodsRestore, periodsTrash, periodsUpdate} from "@/modules/periods/periods-service";

const state = {
  periods: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getPeriods(state) {
    const result = state.periods.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_PERIODS(state, periods) {
    state.periods = periods;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getPeriods({commit}) {
    try {
      const response = await periodsFetch(idSchool);
      const {data} = response;
      commit("SET_PERIODS", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async periodsCreate({commit, dispatch}, payload) {
    try {
      //console.log('periodsCreate',payload);
      const response = await periodsCreate(idSchool, payload);
      dispatch("getPeriods", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async periodsUpdate({commit, dispatch}, payload) {
    try {
      const response = await periodsUpdate(idSchool, payload);
      dispatch("getPeriods", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async periodsDeletex({commit}, query) {
    try {
      const response = await periodsDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async periodsPaginate({commit}, query) {
    try {
      const response = await periodsPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async periodsRemove({commit}, payload) {
    try {
      const response = await periodsRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async periodsTrash({commit}, query) {
    try {
      const response = await periodsTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async periodsRestore({commit}, payload) {
    try {
      const response = await periodsRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
