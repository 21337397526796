import {permissionsCreate, permissionsDeletex, permissionsFetch, permissionsPaginate, permissionsRemove, permissionsRestore, permissionsTrash, permissionsUpdate} from "@/modules/permissions/permissions-service";

const state = {
  permissions: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getAll(){
    return state.permissions;
  },
  getPermissions(state) {
    const result = state.permissions.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_CARDS_TYPES(state, permissions) {
    state.permissions = permissions;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getPermissions({commit}) {
    try {
      const response = await permissionsFetch(idSchool);
      const {data} = response;
      commit("SET_CARDS_TYPES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async permissionsCreate({commit, dispatch}, payload) {
    try {
      //console.log('permissionsCreate',payload);
      const response = await permissionsCreate(idSchool, payload);
      dispatch("getpermissions", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async permissionsUpdate({commit, dispatch}, payload) {
    try {
      const response = await permissionsUpdate(idSchool, payload);
      dispatch("getpermissions", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async permissionsDeletex({commit}, query) {
    try {
      const response = await permissionsDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async permissionsPaginate({commit}, query) {
    try {
      const response = await permissionsPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async permissionsRemove({commit}, payload) {
    try {
      const response = await permissionsRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async permissionsTrash({commit}, query) {
    try {
      const response = await permissionsTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async permissionsRestore({commit}, payload) {
    try {
      const response = await permissionsRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
