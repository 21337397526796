import store from "@/store";
import loadRoute from "@/modules/load-route";
import loadRouteForms from "../modules/load-route-forms";

export default [
    {
        path: "/",
        name: "home",
        component: () => import("@/view/pages/home"),
        meta: {
            layout: "full",
        },
    },
    {
        path: "/plans",
        meta: {authRequired: true},
        component: () => import("@/view/pages/plans/plans/views/Plans.vue"),
    },
    {
        path: "/countries",
        meta: {authRequired: true},
        component: () => import("@/view/pages/countries/views/Countries.vue"),
    },
    {
        path: "/calendar",
        meta: {authRequired: true},
        component: () => import("@/view/Calendar.vue"),
    },
    {
        path: "/states",
        meta: {authRequired: true},
        component: () => import("@/view/pages/states/views/States.vue"),
    },
    {
        path: "/configurations/school/:id",
        param: {
            id: {
                type: Number,
                default: 1,
            },
        },
        meta: {authRequired: true},
        component: () =>
            import(
                "@/view/pages/configurations/school-account/views/SchoolAccount.vue"
                ),
    },
    {
        path: "/cities",
        meta: {authRequired: true},
        component: () => import("@/view/pages/cities/views/Cities.vue"),
    },
    {
        path: "/peoples-types",
        meta: {authRequired: true},
        component: () =>
            import("@/view/pages/peoples/peoples-types/views/PeoplesTypes.vue"),
    },
    {
        path: "/responsibles-peoples-types",
        meta: {authRequired: true},
        component: () =>
            import(
                "@/view/pages/peoples/reponsibles-peoples-types/views/ResponsiblesPeoplesTypes.vue"
                ),
    },
    {
        path: "/responsibles-peoples",
        meta: {authRequired: true},
        component: () =>
            import(
                "@/view/pages/peoples/responsibles-peoples/views/ResponsiblesPeoples.vue"
                ),
    },
    {
        path: "/peoples",
        meta: {authRequired: true},
        component: () => import("@/view/pages/peoples/peoples/views/Peoples.vue"),
    },
    {
        path: "/peoples-races",
        meta: {authRequired: true},
        component: () => import("@/view/pages/peoples/peoples-races/views/PeoplesRaces.vue"),
    },
    {
        path: "/peoples-specialties-types",
        meta: {authRequired: true},
        component: () => import("@/modules/peoples-specialties-types/peoples-specialties-types.vue"),
    },
    {
        path: "/status-checkout",
        meta: {authRequired: true},
        component: () =>
            import("@/view/pages/payments/status-checkout/views/StatusCheckout.vue"),
    },
    {
        path: "/plans-items",
        meta: {authRequired: true},
        component: () => import("@/view/pages/plans/items/views/Items.vue"),
    },
    {
        path: "/peoples-documents-types",
        meta: {authRequired: true},
        component: () =>
            import("@/view/pages/peoples/documents-types/views/DocumentsTypes.vue"),
    },
    {
        path: "/products-types",
        meta: {authRequired: true},
        component: () =>
            import("@/view/pages/products/products-types/views/ProductsTypes.vue"),
    },
    {
        path: "/payments-status",
        meta: {authRequired: true},
        component: () =>
            import("@/view/pages/payments/payments-status/views/PaymentsStatus.vue"),
    },
    {
        path: "/payments-methods",
        meta: {authRequired: true},
        component: () =>
            import(
                "@/view/pages/payments/payments-methods/views/PaymentsMethods.vue"
                ),
    },
    {
        path: "/gateways-payments-methods",
        meta: {authRequired: true},
        component: () =>
            import(
                "@/view/pages/payments/gateways-payments-methods/views/GatewayPaymentsMethods.vue"
                ),
    },
    {
        path: "/permissions",
        meta: {authRequired: true},
        component: () => import("@/modules/permissions/permissions.vue"),
    },
    {
        path: "/gateway-payments",
        meta: {authRequired: true},
        component: () =>
            import("@/view/pages/payments/gateways/views/GatewaysPayments.vue"),
    },
    {
        path: "/gateway-payments-configurations",
        meta: {authRequired: true},
        component: () =>
            import(
                "@/view/pages/payments/gateway-payments-config/views/GatewayPaymentsConfig.vue"
                ),
    },
    {
        path: "/policies",
        meta: {authRequired: true},
        component: () => import("@/modules/policies/policies.vue"),
    },
    {
        path: "/profiles",
        meta: {authRequired: true},
        component: () => import("@/view/pages/profiles/views/Profiles.vue"),
    },
    {
        path: "/users",
        meta: {authRequired: true},
        component: () => import("@/view/pages/users/views/Users.vue"),
    },
    {
        path: "/post-types",
        meta: {authRequired: true},
        component: () => import("@/view/pages/postTypes/PostTypes.vue"),
    },
    {
        path: "/taxonomies-types",
        meta: {authRequired: true},
        component: () => import("@/view/pages/taxonomiesTypes/TaxonomiesTypes.vue"),
    },


    {
        path: "/post-type/:type",
        name: "post-type",
        meta: {authRequired: true},
        component: () => import("@/view/pages/posts-management/views/Posts.vue"),
    },
    {
        path: "/taxonomies/:type",
        name: "taxonomies",
        meta: {authRequired: true},
        component: () => import("@/view/pages/taxonomies/views/Taxonomies.vue"),
    },


    {
        path: "/policies-privacity",
        name: "policies-privacity",
        meta: {layout: "full"},
        component: () =>
            import("@/view/pages/terms&privacity/PoliciesPrivacity.vue"),
    },
    {
        path: "/use-terms",
        name: "use-terms",
        meta: {layout: "full"},
        component: () => import("@/view/pages/terms&privacity/UseTerms.vue"),
    },
    {
        path: "/branch-schools-configurations",
        component: () =>
            import(
                "../view/pages/branch-schools-configuration/views/BranchSchoolsConfigurations.vue"
                ),
        meta: {authRequired: true},
    },

    // MAIN
    {
        path: "/dashboards/analytics",
        name: "dashboards-analytics",
        meta: {authRequired: true},
        component: () => import("@/view/main/dashboards/analytics"),
    },
    {
        path: "/pages/profile/personel-information",
        name: "profile-personel-information",
        authRequired: true,
        component: () => import("@/view/pages/profile"),
        meta: {
            title: "Profile",
            breadcrumb: [
                {
                    text: "Pages",
                },
            ],
        },
    },
    {
        path: "/pages/profile/security",
        name: "profile-security",
        authRequired: true,
        component: () => import("@/view/pages/profile"),
        meta: {
            title: "Profile",
            breadcrumb: [
                {
                    text: "Pages",
                },
            ],
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/view/pages/authentication/login"),
        meta: {
            layout: "full",
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch("auth/validate").then((validUser) => {
                    !validUser
                        ? next()
                        : next({
                            name: "dashboards-analytics",
                            query: {redirectFrom: routeTo.fullPath},
                        });
                });
            },
        },
    },
    {
        path: "/register",
        name: "register",
        component: () => import("@/view/pages/authentication/register"),
        meta: {
            layout: "full",
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/view/pages/authentication/recover"),
        meta: {
            layout: "full",
            authRequired: false,
        },
    },
    {
        path: "/pages/authentication/reset-password",
        name: "reset",
        authRequired: true,
        component: () => import("@/view/pages/authentication/reset"),
        meta: {
            layout: "full",
        },
    },
].concat(loadRoute, loadRouteForms);
